var exports = {};
var $Map = typeof Map === "function" && Map.prototype ? Map : null;
var $Set = typeof Set === "function" && Set.prototype ? Set : null;
var exported;
if (!$Set) {
  /** @type {import('.')} */
  // eslint-disable-next-line no-unused-vars
  exported = function isSet(x) {
    // `Set` is not present in this environment.
    return false;
  };
}
var $mapHas = $Map ? Map.prototype.has : null;
var $setHas = $Set ? Set.prototype.has : null;
if (!exported && !$setHas) {
  /** @type {import('.')} */
  // eslint-disable-next-line no-unused-vars
  exported = function isSet(x) {
    // `Set` does not have a `has` method
    return false;
  };
}

/** @type {import('.')} */
exports = exported || function isSet(x) {
  if (!x || typeof x !== "object") {
    return false;
  }
  try {
    $setHas.call(x);
    if ($mapHas) {
      try {
        $mapHas.call(x);
      } catch (e) {
        return true;
      }
    }
    // @ts-expect-error TS can't figure out that $Set is always truthy here
    return x instanceof $Set; // core-js workaround, pre-v2.5.0
  } catch (e) {}
  return false;
};
export default exports;